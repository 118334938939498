import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../../shared/models/user.model';
import { API } from './../../app.api';
import { StorageService } from './localsorage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  helper = new JwtHelperService();
  private _user: User;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorageService: StorageService
  ) {}


  isLoggedIn(): boolean {
    if(this.localstorageService.getLocalStorage("user_farmjob")){
      return true
    }
  }

  login(email: string, password: string): Observable<any>{
    return this.http.post(`${API}/auth/login/adm`, { credential : email, password: password })
        .pipe(tap(user =>{
          this._user = user
          this.initUser(user)
        }))
  }

  logout() {
    this.localstorageService.removeLocalStorage("user_farmjob")
    delete this._user;
    this.router.navigate(['/'])
  }

  private initUser(response) {
    this._user = response;
    this.localstorageService.setLocalStorage("user_farmjob", this._user)
  }

  esqueceuSenha(email:string){
    return this.http.put(`${API}/user/change-password`, {email: email})
  }

  private isValid() {
    return !!this.user;
  }

  get user() {
    if (this._user === undefined && this.localstorageService.getLocalStorage("user_farmjob") != null) {
      this._user = this.localstorageService.getLocalStorage("user_farmjob");
    }
    return this._user;
  }

  getTokenExpired(){
    return this.helper.isTokenExpired(this.token)
  }

  get token() {
    return this.isValid() && this.user.token;
  }

  get isAdmin(){
    return this.isValid() && this.user.type == 'admin';
  }

}
