import { LoginGuard } from './core/guards/login.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '',  loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule), canActivate: [LoginGuard] },
  { path: 'admin',  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard], canLoad: [AdminGuard] },
  { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
